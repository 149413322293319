import { render, staticRenderFns } from "./CtaSectionBanner.vue?vue&type=template&id=dedaee4e&scoped=true"
import script from "./CtaSectionBanner.vue?vue&type=script&setup=true&lang=ts"
export * from "./CtaSectionBanner.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CtaSectionBanner.vue?vue&type=style&index=0&id=dedaee4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dedaee4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiLink.vue').default})
